import React, { useEffect } from "react";

const HubspotContactForm = ({ formId, targetId = "hubspotForm" }) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js.hsforms.net/forms/v2.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            if (window.hbspt) {
                window.hbspt.forms.create({
                    portalId: '46721556',
                    formId: formId,         // dynamically passed formId
                    target: `#${targetId}`  // default or custom targetId
                });
            }
        });

        // Clean up script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, [formId, targetId]);

    return (
        <div>
            <div id={targetId}></div>
        </div>
    );
};

export default HubspotContactForm;