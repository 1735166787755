import React from 'react';
import { Helmet } from 'react-helmet';
import Badges from './badges';
import CalloutBar from './calloutBar';
import Contact from './contact';
import Header from './header';
import Hero from './hero';
import Interlude from './interlude';
import Intro from './intro';
import Services from './services';
import Tagline from './tagline';
import Testimonials from './testimonials';
import Footer from './footer';
import SidebarForm from './forms/sidebar-form';

//temp
import Split from './layout/split';
import Left from './layout/left';
import Right from './layout/right';
import FullwidthForm from './forms/fullwidth-form';
import HubspotContactForm from './forms/hubspot-contact-form';
import Locations from './locations';
import Specials from './specials';
//end temp

import GlobalStyle from '../styles/globalStyles';

function pluckOverride(pageData, categoryData) {
  if (Array.isArray(pageData)) {
    if (!pageData.length) {
      return categoryData;
    } else {
      return pageData;
    }
  }
  if (!pageData || pageData.length === 0) {
    return pageData;
  } else {
    return categoryData;
  }
}

export default function Layout({ sanityData, children, slug }) {
  //overwriteable per page
  const address =
    sanityData?.company_overrides?.address ||
    sanityData?.category?.companyInfo?.address;
  const company =
    sanityData?.company_overrides?.company ||
    sanityData?.category?.companyInfo?.company;
  const phone =
    sanityData?.company_overrides?.phone ||
    sanityData?.category?.companyInfo?.phone;
  const emailRecipients =
    sanityData?.company_overrides?.email ||
    sanityData?.category?.companyInfo?.email;
  const logoSrc =
    sanityData?.company_overrides?.logo?.secure_url ||
    sanityData?.category?.companyInfo?.logo?.secure_url;
  const iconSrc =
    sanityData?.company_overrides?.icon?.secure_url ||
    sanityData?.category?.companyInfo?.icon?.secure_url;
  const introImage =
    sanityData?.intro_image_override?.secure_url ||
    sanityData?.category?.intro_image?.secure_url;
  const interludeImageSrc = 
    sanityData?.interlude_image_override?.secure_url ||
    sanityData?.category?.interlude_image?.secure_url;
  const tagline =
    sanityData?.company_overrides?.tagline ||
    sanityData?.category?.companyInfo?.tagline;
  const testimonials = pluckOverride(
    sanityData?.testimonials_override,
    sanityData?.category?.testimonials
  );
  const specials = pluckOverride(
    sanityData?.specials_override,
    sanityData?.category?.specials
  );
  let locations =
    sanityData?.service_area_override?.locations ||
    sanityData?.category?.serviceArea?.locations;
  const gtmId = sanityData?.category?.gtm;
  //Not overwriteable per page
  const conversionId = sanityData?.category?.conversionId;
  const phoneConversionLabel = sanityData?.category?.phoneConversionLabel;
  const formConversionLabel = sanityData?.category?.formConversionLabel;
  const chatConversionLabel = sanityData?.category?.chatConversionLabel;
  const heroBackground = sanityData?.hero_banner?.secure_url;
  const heroTitle = sanityData?.hero_special?.title;
  const heroSubTitle = sanityData?.hero_special?.details;
  const heroContent = sanityData?.hero_special?.disclaimer;
  const heroHideForm = sanityData?.hero_hide_form;
  const calloutMessage = sanityData?.category?.calloutMessage;
  const calloutImage = sanityData?.category?.callout_image?.secure_url;
  const calloutGradient = sanityData?.category?.calloutGradient;
  const introText = sanityData?._rawIntro;
  const services = sanityData?.category?.services;
  const testimonialBackground =
  sanityData?.category?.testimonialBackground?.secure_url;
  const interludeText = sanityData?.interlude_text;
  const serviceAreaBackground =
    sanityData?.category?.serviceAreaBackground?.secure_url;
  const badgeObjs = sanityData?.category?.badges;
  const hasClr = sanityData?.category?.companyInfo?.hasClr;
  //colors
  const primaryColor = sanityData?.category?.primaryColor?.hex;
  const secondaryColor = sanityData?.category?.secondaryColor?.hex;
  const primaryBtnColor =
    sanityData?.category?.colorOverrides?.primaryBtnColor?.hex || primaryColor;
  const secondaryBtnColor =
    sanityData?.category?.colorOverrides?.secondaryBtnColor?.hex ||
    secondaryColor;
  const formBgColor =
    sanityData?.category?.colorOverrides?.formBgColor?.hex || secondaryColor;
  const lineColor =
    sanityData?.category?.colorOverrides?.lineColor?.hex || primaryColor;
  const gradientStartColor =
    sanityData?.category?.colorOverrides?.gradientStartColor?.hex ||
    primaryColor;
  const gradientEndColor =
    sanityData?.category?.colorOverrides?.gradientEndColor?.hex ||
    secondaryColor;
  const reviewBgColor =
    sanityData?.category?.colorOverrides?.reviewBgColor?.hex || secondaryColor;
  const reviewBubbleColor =
    sanityData?.category?.colorOverrides?.reviewBubbleColor?.hex ||
    primaryColor;
  const specialsBgColor =
    sanityData?.category?.colorOverrides?.specialsBgColor?.hex || primaryColor;
  const headingTextColor =
    sanityData?.category?.colorOverrides?.headingTextColor?.hex ||
    secondaryColor;
    const iconColor = 
    sanityData?.category?.colorOverrides?.iconColor?.hex || 
    secondaryColor;
  // Custom Hubspot Integration
  const formIdMap = {
    'virginia/garage-door-tune-up': 'bd4107da-42ef-436e-8c32-b0757c8e5673',
    'virginia/garage-door-springs': '72308539-1ae7-412a-bc13-3ccd44479217',
    'virginia/garage-door-repairs': 'd3eeae48-cb8f-4b53-a206-fe39df6ecb8e',
    'virginia/garage-door-company': '4e436263-0fb7-4d61-919e-68387d43e376',
    'colorado/garage-door-tune-up': 'd6f3e8f3-b8d9-4633-a9d9-184167ae23c0',
    'colorado/garage-door-springs': 'a8f5ef19-7179-484a-ac81-c23091842507',
    'colorado/garage-door-repairs': 'f7c3d506-b8c8-4c2e-873c-d5c75b30a8e4',
    'colorado/garage-door-company': '2062dc24-0f47-4383-b048-c7a28af1a43f',
    'maryland/garage-door-tune-up': 'fd063ef5-5cad-41ff-ae5f-bc7614f3c243',
    'maryland/garage-door-springs': '661a7649-87f7-425d-be96-01db9c08214d',
    'maryland/garage-door-repairs': 'f972eeff-6bf7-486b-9e53-1604e58a17ac',
    'maryland/garage-door-company': '84a71751-da94-4375-9d3c-34d770973d18',
    'washington/garage-door-tune-up': '3b7bfca9-59fe-4388-a158-d2a6aa942ef4',
    'washington/garage-door-springs': '349dba5b-765f-4864-a658-6abb4f7b3216',
    'washington/garage-door-repairs': '513a8d3d-7ce3-4d7f-a47d-a196275f670c',
    'washington/garage-door-company': '350040cd-ed55-47a5-8225-7bf97095291a',
    'new-jersey/garage-door-tune-up': 'dc00f31b-4cd4-47e0-98b5-9b4e9dc65bb7',
    'new-jersey/garage-door-springs': '97f13700-1f01-4c8a-9d18-0201c79b416e',
    'new-jersey/garage-door-repairs': 'fc5ec211-05ef-4440-b1c6-899cb935514f',
    'new-jersey/garage-door-company': 'b2581aeb-51f8-4d8a-958b-e55a4ac5fa36',
    'illinois/garage-door-company': 'bc816c0a-88b2-4801-8898-c0121d254e32',
    'oregon/garage-door-company': 'c7443384-3b01-49e3-ae52-4318379c2c73',
    'virginia/garage-door-installation': '4f0e4218-824b-461b-85c1-1e189b2c1ffa',
    'virginia/garage-door-opener': '3ff59a2e-5e32-4b09-aeb6-55984c8c6d25',
    'virginia/garage-door-types': 'c174ec84-ba4f-4785-a6d9-9e8b74d95083',
    'colorado/garage-door-installation': '92d7340d-d3c7-40ac-8bfa-0027eb8d3e9a',
    'colorado/garage-door-opener': '7405aef6-7b88-49cc-b55b-c6cc46377d23',
    'colorado/garage-door-types': '5e7b1839-98ed-432e-8d95-8f93690e9795',
    'maryland/garage-door-installation': 'b26f352b-2e14-43bf-9f6a-631ac93dbdcd',
    'maryland/garage-door-opener': 'da65422b-7364-4f98-98cc-db46d4fa6b62',
    'maryland/garage-door-types': '22e33afc-ac5a-4cf7-b96e-4a43b0753d8a',
    'washington/garage-door-installation': '3b821ce7-f1db-40dd-bb22-7c4f0d288f53',
    'washington/garage-door-opener': 'eca7d51d-9b83-4562-bfc7-2b1da1cc3d81',
    'washington/garage-door-types': '23711308-bc89-4aa1-acc9-089f8d718181',
    'illinois/garage-door-installation': '6c8f1381-4591-4df4-af0b-6e60f533684a',
    'illinois/garage-door-opener': 'a46f2f40-f937-48cc-b4dd-df451da39e56',
    'illinois/garage-door-repairs': 'efdffd0e-32d8-4c38-be5f-9a9a3bd1a37f',
    'illinois/garage-door-springs': '602988cc-34f8-4a70-ac50-035023c503d6',
    'illinois/garage-door-tune-up': 'a15e5801-a1e8-451f-9aae-5914599fb842',
    'illinois/garage-door-types': '89d2a585-888e-4d1f-a88e-dc62b4ee3a00',
    'new-jersey/garage-door-installation': '9a6aa42f-7e05-45c5-9314-04ce138c9569',
    'new-jersey/garage-door-opener': 'd2f35514-c92c-4e07-bff6-4c33d8593636',
    'new-jersey/garage-door-types': '3c741d4d-2091-45c9-92aa-461ae1fab38c',
    'new-york/garage-door-company': 'a3cd90dc-9bff-4948-878f-a618ada717dd',
    'new-york/garage-door-installation': 'bc52a4f2-b878-49cd-ae7b-fec22ccffd12',
    'new-york/garage-door-opener': '3d9ab2b2-c4f4-4efa-a728-e1ad62cb4d1f',
    'new-york/garage-door-repairs': 'e9751c08-17ba-4fdf-b150-f0ed6f5388d3',
    'new-york/garage-door-springs': '2e538c7d-fe7b-48f7-8954-9dd4fb2333df',
    'new-york/garage-door-tune-up': '6502b9f1-1e47-4898-a6b3-2bdfff8e6736',
    'new-york/garage-door-types': '9b2dac83-771c-4c37-812a-295dcd257ca3',
    'oregon/garage-door-installation': 'b7e6114d-8e6b-44b5-894f-235bd4ac61ab',
    'oregon/garage-door-opener': 'c22c4cec-cf41-4b30-8ca1-26e09cae241b',
    'oregon/garage-door-repairs': 'e1d5163c-4c36-47d3-9a3b-0cbd121d268e',
    'oregon/garage-door-springs': '073e9a69-2c55-4262-ad89-66dc4e45cdd7',
    'oregon/garage-door-tune-up': '805f87f0-ea42-4b70-93da-dabcb5b4eb00',
    'oregon/garage-door-types': '9ca78166-1bfc-4607-95b3-f2f0535f4ba6',
  };
  const formId = formIdMap[slug] || 'default-form-id';
  // End Custom Hubspot Integration
  return (
    <>
      <GlobalStyle
        {...{
          primaryBtnColor,
          secondaryBtnColor,
          lineColor,
          headingTextColor,
          primaryColor,
          secondaryColor,
          formBgColor,
          gradientStartColor,
          gradientEndColor,
          reviewBgColor,
          reviewBubbleColor,
          specialsBgColor,
          iconColor
        }}
      />
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
        <link
          href="https://cdn.icomoon.io/198172/ryno-lp-factory/style.css"
          rel="stylesheet"
          type="text/css"
        />
        <link rel="icon" href={iconSrc} />
        <script>
          {`
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                  'formSubmitted': false,
                  'formattedPhone': '${phone}',
                  'phoneConversionId': '${conversionId}',
                  'phoneConversionLabel': '${phoneConversionLabel}',
                  'formConversionId': '${conversionId}',
                  'formConversionLabel': '${formConversionLabel}',
                  'chatConversionLabel': '${chatConversionLabel}'
              });

              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${gtmId}');
          `}
        </script>
        <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/46721556.js"></script>
      </Helmet>
      <Header {...{ logoSrc, phone }} />
      <Hero
        {...{
          heroBackground,
          formBgColor,
          heroTitle,
          heroSubTitle,
          emailRecipients,
          heroContent,
          hasClr,
          heroHideForm,
        }}
      >
        <SidebarForm recipients={emailRecipients} hasClr={hasClr} />
      </Hero>
      <Intro {...{ introText, introImage }} />
      <CalloutBar {...{ calloutMessage, calloutImage, calloutGradient }} />
      <Services {...{ lineColor, services, iconSrc }} />
      <Testimonials
        {...{
          testimonialBackground,
          reviewBgColor,
          reviewBubbleColor,
          testimonials,
          lineColor,
        }}
      />
      <Interlude {...{ interludeText, interludeImageSrc }} />
      <Split>
        <Left id="specials">
          {specials.length > 0 ? (
            <Specials {...{ specials, lineColor, iconSrc }} />
          ) : (
            <>
              <h2 className="font-semibold text-center mb-7 leading-9 text-2xl phablet:text-3xl tablet:text-4xl laptop:text-5xl desktop:text-6xl">
                Contact Our <span className="font-bold">Specialists Today</span>
              </h2>
              <FullwidthForm
                recipients={emailRecipients}
                isSplit={false}
                hasClr={hasClr}
              />
            </>
          )}
        </Left>
        <Right background={serviceAreaBackground}>
          <Locations locations={locations} />
        </Right>
      </Split>
      <Contact>       
        <HubspotContactForm formId={formId} />
      </Contact>
      <Tagline {...{ tagline, lineColor, iconSrc }} />
      <Badges badges={badgeObjs} />
      <Footer {...{ company, address, phone }} />
    </>
  );
}
